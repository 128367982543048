import React from 'react';
import PaginatedTable from './paginate'; // Adjust the path accordingly

const Paginated = () => {
  return (
    <div>
      {/* Render the PaginatedTable component */}
      <PaginatedTable />
    </div>
  );
};

export default Paginated;